<template>
  <tr>
    <td v-if="hidden.id">
      <span v-show="!item.parentId">
        {{item.id}}
      </span>
    </td>
    <td v-if="hidden.name">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.approvedAt, 'text-warning': !ledger.approvedAt}">
          {{ledger.account.code}}: {{ledger.account.name}}
        </small>
        <br>
      </span>
      <span style="white-space: pre-line;">{{ item.name }}</span>
      <small v-if="withholdingType">
        <div class="text-info">
          {{item.withholdingTopic}}.
          {{item.withholdingName}}
          ({{item.withholdingRate}}%)
          ({{item.withholdingPrice}})
        </div>
        <div class="text-info">
          {{withholdingType.text}}
        </div>
        <div v-if="item.withholdingRemark" class="text-danger">
          หมายเหตุ: {{item.withholdingRemark}}
        </div>
      </small>
    </td>
    <td v-if="hidden.qty">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      -
    </td>
    <td v-if="hidden.price">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      <span v-if="item.price > 0">{{ item.price | comma  }}</span>
      <span v-else>-</span>
    </td>
    <td v-if="hidden.totalPrice">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      {{ item.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      withholdingTypes: [
        {text: 'หักภาษี ณ ที่จ่าย', value: 1},
        {text: 'ออกภาษีให้ครั้งเดียว', value: 2},
        {text: 'ออกให้ตลอดไป', value: 3},
        {text: 'อื่นๆ', value: 4},
      ]
    }
  },
  computed: {
    withholdingType () {
      return this.withholdingTypes.find(v => v.value === this.item.withholdingType)
    },
    item () {
      return this.row.item
    },
    hidden () {
      return this.row.hidden
    }
  }
}
</script>

<style lang="css" scoped>
</style>
