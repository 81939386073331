<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">
    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <DetailForm
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :formData="formData"
          :method="method"
          :configs="docConfigs"
          @updated="dataDetail">
        </DetailForm>
      </sgv-form>

      <template v-if="docId > 0 && formData.contactId">
        <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

        <template v-for="tab in tabs">
          <component
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :key="tab.value"
            :is="tab.value"
            v-if="tab.value === selectedTab && docId > 0"
            :method="method"
            :formData="formData"
            :configs="docConfigs">
          </component>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailForm from './DetailForm'
import DetailOrder from './DetailOrder'
import DetailPrint from './DetailPrint'
import Attachment from '@/views/doc_core/components/Attachment'
import {
  DETAIL_DOC,
  UPDATE_DOC,
  WATCH_DOC_UPDATED,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.formData.code}` || `${this.title}`
    }
  },
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      formData: {
        type: '',
        code: '',
        name: '',
        remark: '',
        contactId: null,
        createdAt: null,
        createdUser: null,
        approvedAt: null,
        approvedUser: null,
        closedAt: null,
        closedUser: null,
        categories: [],
        contact: null
      },
      selectedTab: 'DetailOrder',
      docConfigs: [],
      isLoaded: false,
    }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result (res) {
          this.setFormData(res.data.docUpdated)
        }
      }
    },
  },
  computed: {
    tabs () {
      let arr = [
        {text: 'รายการ', value: 'DetailOrder'},
        {text: 'พิมพ์', value: 'DetailPrint'},
        {text: 'ไฟล์', value: 'Attachment'},
      ]
      return arr
    },
    options () {
      return [
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.doc)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    updateData () {
      this.$apollo.mutate({
        mutation: UPDATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: {
            code: this.formData.code,
            remark: this.formData.remark,
            categories: this.formData.categories
          }
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    this.dataDetail()
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    DetailOrder,
    Attachment,
    DetailPrint
  }
}
</script>

<style lang="css">
</style>
